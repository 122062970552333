/* Container for the entire By-Laws component */
.bylaws-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

/* Styles for the main title */
h1 {
    padding-top: 3px;
    padding-left: 5px;
    text-indent: 0;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
}

/* Styles for the subtitle */
.subtitle {
    padding-left: 5px;
    text-indent: 0;
    text-align: left;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 20px;
}

/* Paragraph styles */
p {
    padding-left: 5px;
    text-indent: 0;
    text-align: justify;
    margin: 10px 0;
}

/* Ordered list styles */
ol {
    padding-left: 40px;
    margin: 10px 0;
}

ol li {
    margin: 5px 0;
}

/* Heading styles */
h2 {
    padding-left: 5px;
    text-indent: 0;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}
li {
    font-size: 14px;
 }
/* Additional styling for a polished appearance */
.bylaws-container h1,
h2 {
    color: #333;
}

.bylaws-container p,
ol {
    color: #555;
}
