/* ContactUs.css */

.contact-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  
  .contact-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    height: 100px;
    resize: none;
  }
  
  .contact-form button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  