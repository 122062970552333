body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image:url("../../public/assets/grain.gif");
  background-attachment:fixed;
  background-repeat: repeat-y;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.master-table {
  font-size: 14px; /* Slightly smaller font size for links */
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  max-width: 100%;
  padding-left: 10px;
  padding-right: 80px;
}

.master-row {
  display: flex;
  width: 100%;
}

.master-column {
  padding-left: 80px;
}

.master-section {
  flex: 2; 
  text-align: justify; 
}

/**********************************************/
/* Competition Winners */
/**********************************************/

.parent-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.file-list-wrapper {
  text-align: center; /* Centers text content */
}

.file-list {
  list-style-type: none; /* Removes bullet points */
  padding: 0;
}

.file-item {
  font-size: 1.5em;
}



/**********************************************/
/* Footer */
/**********************************************/

.footer-table {
  border-collapse: collapse; /* Optional, for better table design */
  font-size: 10px; /* Slightly smaller font size for links */
}

.footer-table td {
  text-align: center; /* Centers content horizontally */
  vertical-align: middle; /* Centers content vertically */
  padding: 8px; /* Optional, for cell padding */
}

/* Optional: You can also target specific rows if needed */
.footer-table .footer-row td {
  text-align: center;
  vertical-align: middle;
}

/**********************************************/
/* Navbar */
/**********************************************/

.navbar {
  background-color: transparent;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers items evenly */
  align-items: center;
}

.nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 80%; /* Ensures spacing remains even */
  justify-content: space-evenly;
}

.nav-item {
  position: relative;
  flex-grow: 1; /* Allows items to expand evenly */
  text-align: center; /* Centers text */
}

.nav-item a {
  color: black;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  font-size: 16px;
  transition: color 0.3s ease;
}

.nav-item a:hover {
  color: blue;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); /* Centers dropdown */
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.dropdown-item {
  width: 100%;
  text-align: center;
}  
  .dropdown-item:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
  
  .dropdown-item:first-child {
    border-top: 2px solid black; /* Black line above the first item */
  }
  
  .dropdown-item a {
    padding: 10px 15px;
    display: block;
    color: black;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif; /* Use sans-serif font for dropdown links */
    font-size: 14px; /* Base font size for navigation item */
  }
  
  .dropdown-item a:hover {
    background-color: lightgray;
  }  

/**********************************************/
/* Brew Note */
/**********************************************/

/* Container for the entire list */
.link-list-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

/* Style for the dropdown select */
.year-dropdown {
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

/* Grid layout for the 3 columns */
.link-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

/* Style for each individual column */
.link-column {
  list-style: none;
}

/* Remove bullet points from the list */
.link-list {
  list-style-type: none;
  padding-left: 0;
}

/* Style for each link item */
.link-item {
  margin-bottom: 10px;
}

.link-item a {
  text-decoration: none;
  color: #1a73e8;
  font-weight: bold;
  transition: color 0.3s ease;
}

/* Hover effect for links */
.link-item a:hover {
  color: #0c57a1;
}
