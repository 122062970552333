body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    margin: 15px;
    color: #333;
  }
  
 
  .officer-title {
    font-size: 1.2em;
    color: #222;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .officer-group {
    margin-bottom: 15px;
}
  
.officer-img {
  width: 80px;
  text-align: center;
}
.officer-text {
  padding-left: 10px;
}

  