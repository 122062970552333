  .centered-offset {
    margin-left: 60px; /* Offset 60px from the left */
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align the content horizontally */
}

/* Optional: Styling for the image and header */
.centered-offset img {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto;
}

.centered-offset h2 {
    text-align: center; /* Center the text */
    color: rgb(128, 114, 207);
}



.home-table {
    font-size: 14px; /* Slightly smaller font size for links */
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    max-width: 100%;
    padding-left: 60px;
    line-height: 1.2;
  }
  
  .home-row {
    display: flex;
    width: 100%;
  }
  
  .home-column {
    padding-left: 30px;
  }
  
  .large-section {
    flex: 2; 
    text-align: justify; 
  }
  
  .small-section {
    flex: 1; 
    text-align: justify; 
    font-size: 10pt;
    line-height: 1.1;
  }
  
  .small-section img {
    width: 100%;
    height: auto;
  }
  
  /* Optional: Add responsiveness */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }


.social-img {
    width: 40px;
    text-align: center;
}

.social-text {
    font-size: 9pt;
    padding-left: 10px;
    line-height: 1.1;
}
