body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  margin: 15px;
  color: #333;
}

.month-section {
  margin-bottom: 30px;
}

.month-title {
  font-size: 1.5em;
  color: #444;
  margin-bottom: 8px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
}
.month-intro {
  font-size: 1.5em;
  color: #444;
  margin-bottom: 8px;
  padding-bottom: 3px;
}


.group-title {
  font-size: 1.2em;
  color: #222;
  margin-top: 15px;
  margin-bottom: 5px;
}

.styles-group {
  margin-bottom: 15px;
}

.styles-list {
  margin: 0;
  padding: 0;
  list-style-type: none; /* Removes bullet points */
}

li {
  margin: 0; /* Removed vertical margin between list items */
  font-size: 0.8em; /* Smaller font size */
  line-height: 1.2; /* Reduced line-height for tighter spacing */
}

/* Two-column layout for British Strong Ale */
.two-column-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
  gap: 5px; /* Reduced space between the columns */
}
