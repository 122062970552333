.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.photo-year-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.photo-year-dropdown {
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: auto;
  min-width: 100px;
}

/* Grouped Photos */
.photo-group {
  margin-bottom: 30px;
}

.photo-grid {
  display: flex;
  gap: 10px; /* Adds spacing between small photos */
  flex-wrap: wrap;
  justify-content: center;
}

.photo-thumbnail {
  cursor: pointer;
  width: 80px;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.photo-thumbnail:hover {
  transform: scale(1.05);
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content img {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 5px;
  margin-bottom: 10px;
}

.modal-content p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.modal-close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-close-button:hover {
  background: darkblue;
}
